<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">
        Leaderboards
      </v-card-title>

      <v-card-text>      
        Customize the leaderboard configuration for this event. You can drag 'n drop leaderboard into a custom order.
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="races"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Leaderboards</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:body="props">
          <draggable :list="races" tag="tbody" handle=".handle" @change="reorderRaces">
            <tr v-for="(item, index) in races" :key="index">
              <td v-if="true">
                <v-icon small class="handle">fa-sort-circle</v-icon>
                <span v-if="false" class="ml-2 text-muted">{{index}}/{{item.rank}}</span>
              </td>
              <td>
                <span class="font-weight-bold">{{ item.name }}</span>
                <div v-if="item.team" class="font-weight-bold">({{$helpers.displayText('team_types', item.team, 'UNKNOWN') }})</div>
              </td>
          <td>
            <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
            <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
              <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
              <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
            </span>
          </td>
          <td>
            {{ item.visibility || 'VISIBLE' | titleize }}
          </td>
          <td>
            <v-icon :title="item.scoring">{{$helpers.getScoringIcon(item.scoring)}}</v-icon>
          </td>
          <td>
            <div v-if="item.min_dist" class="text-muted">
              Activities: {{ $helpers.getDistanceForDisplay($options, item, item.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, item, item.max_dist, event.unit) }}
            </div>
            <div v-if="item.min_duration_s" class="text-muted">
              Activities &gt; {{ item.min_duration_s | duration }}
            </div>
            <div v-if="item.from" class="text-muted">
              Between {{item.from | localDate("L", /* inOriginalTimeZone */ true)}} and {{item.till | localDate("L", /* inOriginalTimeZone */ true)}}
            </div>
          </td>
          <td>
            {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event) }}
          </td>
              
            </tr>
          </draggable>
        </template>        
      </v-data-table>
      <v-card-text>
        <v-btn color="primary" large @click="save">Save Leaderboard Order</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import draggable from 'vuedraggable'

export default {
  name: "Leaderboards",
  components: {
    draggable,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      races: [],
      siteData: siteData,
      headers: [
        { text: 'Sorting', align: 'start', sortable: false, },
        { text: 'Name', align: 'start', sortable: false, value: 'name',},
        { text: 'Activities', align: 'start', sortable: false, value: 'activity_types',},
        { text: 'Visibility', value: 'filter', sortable: false, value: 'visibility' },
        { text: 'Scoring', align: 'start', sortable: false, value: 'scoring',},
        { text: 'Filter', value: 'filter', sortable: false },
        { text: 'Goal', value: 'dist', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
    EventBus.$on('eventmanager-refresh', async state => {
      this.getEvent();
    });

  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    next();
  },
  methods: {
    async getEvent() {
      console.log('Loading new event', this.event, 'etag', this.event && this.event._etag);
      //this.event = (await eventManagerService.get(id)).data;
      if (this.event) {
        this.races = this.event.races || [];
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    reorderRaces(arg) {
      //arg.moved.element.rank = arg.moved.newIndex + 1;
      //console.log('sort', arg);

      this.$nextTick(() => {
        //console.log('re-applyings ranks now', arg);
        var idx = 1;
        for (const item of this.races) {
          if (idx != item.rank) {
            //console.log('change rank', col.rank, 'to', idx);
          }
          item.rank = idx;
          idx++;
        }
      });
    },

    async save() {
      console.log('Saving', this.races, 'event', this.event);
      for (const item of this.races) {
        console.log(`${item.rank}. ${item.name}`);
      }
      this.event.races = this.races;
      this.eventUtil.save();

      await this.getProfile();
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Leaderboards', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    event() {
      this.getEvent();
    }
  },

};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
.handle {cursor: move;}
</style>

